
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/anquanxunjian";
import File from "@/api/file/index.ts";
import PersonliableDialog from "@/components/personliabledialog/index.vue";

@Options({
  name: "Xunjianadd",
  components: {
    PersonliableDialog,
  },
})
export default class extends Vue {
  bAllow = true;
  submitform = {
    title: "",
    areaId: "",
    dangerTypeId: "",
    dangerSourceId: "",
    result: "",
    remark: "",
    fileIds: "",
    changePersonId: "",
  };
  showAreaPicker = false;
  showDangerTypePicker = false;
  showDangerSourceIdPicker = false;
  resultPicker = false;
  dangerDialog = false;
  dangerSourceDialog = false;
  uploadFiles = [];
  private areaColumns: any[] = [];
  private dangerTypeColumns: any[] = [];
  dangerSourceIdPickerColumns: any[] = [];
  resultPickerscolumns = [
    { id: 1, name: "正常" },
    { id: 2, name: "异常" },
  ];
  areaName = "";
  dangerTypeIdname = "";
  dangerSourceIdname = "";
  resultname = "";

  addDangerValue = "";
  dangersourcevalue = "";
  dangersourceremark = "";
  personliableName = "";
  onAreaConfirm(val: any) {
    if (val.name === "新增") {
      this.$router.push({ name: "Xunjianareaadd" });
    } else {
      this.areaName = val.name;
      this.submitform.areaId = val.id;
    }
    this.showAreaPicker = false;
  }
  onDangerConfirm(val: any) {
    if (val.name === "新增") {
      this.dangerDialog = true;
    } else {
      this.submitform.dangerTypeId = val.id;
      this.dangerTypeIdname = val.name;
    }
    this.showDangerTypePicker = false;
  }
  onDangerSourceConfirm(val: any) {
    if (val.name === "新增") {
      this.dangerSourceDialog = true;
    } else {
      this.submitform.dangerSourceId = val.id;
      this.dangerSourceIdname = val.name;
    }
    this.showDangerSourceIdPicker = false;
  }
  onResultConfirm(val: any) {
    this.submitform.result = val.id;
    this.resultname = val.name;
    if (this.resultname === "正常") {
      this.submitform.changePersonId = "";
      this.personliableName = "";
    }
    this.resultPicker = false;
  }
  fOpenAreaPicker() {
    api
      .projectArealist({
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res) => {
        this.areaColumns = res.data;
        this.areaColumns.unshift({
          name: "新增",
          id: 0,
        });
        this.showAreaPicker = true;
      });
  }
  async fOpenDangerTypePicker() {
    let res = await api.dangertypelist({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    this.dangerTypeColumns = res.data;
    this.dangerTypeColumns.unshift({
      id: 0,
      name: "新增",
    });
    this.showDangerTypePicker = true;
  }
  async fOpenDangerSourcePicker() {
    if (!this.submitform.areaId) {
      return this.$dialog.alert({
        title: "提示",
        message: "请先选择您所在的区域",
      });
    }
    let res = await api.dangersourcelist({
      projectId: this.$store.state.project.projectinfo.projectId,
      areaId: this.submitform.areaId,
    });
    this.dangerSourceIdPickerColumns = res.data;
    this.dangerSourceIdPickerColumns.unshift({
      id: 0,
      name: "新增",
    });
    this.showDangerSourceIdPicker = true;
  }
  @Watch("uploadFiles", { deep: true })
  watchFile(files: any) {
    let obj = files.find((file: any) => {
      return file.status === "uploading";
    });
    if (!obj) {
      this.bAllow = true;
    } else {
      this.bAllow = false;
    }
  }
  afterRead(files: any) {
    let filearr: any = [];
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "inspectionmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        file.status = "done";
        file.id = res.data.id;
      });
    });
  }
  fSumbitForm() {
    (this as any).$refs.form.submit();
  }
  async onSubmit() {
    let arr = "";
    this.uploadFiles.map((item: any) => {
      arr += "," + item.id;
    });
    this.submitform.fileIds = arr.substr(1, arr.length - 1);
    let result: any = await api.mobilesourceChecksave({
      projectId: this.$store.state.project.projectinfo.projectId,
      ...this.submitform,
    });
    if (result.code !== 0) {
      return this.$toast.fail(result.msg);
    }
    this.$toast.success({
      message: result.msg,
      forbidClick: true,
      onClose: () => {
        this.submitform = {
          title: "",
          areaId: "",
          dangerTypeId: "",
          dangerSourceId: "",
          result: "",
          remark: "",
          fileIds: "",
          changePersonId: "",
        };
        this.uploadFiles = [];
        this.areaName = "";
        this.dangerTypeIdname = "";
        this.dangerSourceIdname = "";
        this.resultname = "";
        this.personliableName = "";
        (this as any).$mitter.emit("refresh");
        this.$router.go(-1);
      },
    });
  }
  fDangerBeforeClose(action: string) {
    if (action === "confirm") {
      return false;
    } else {
      return true;
    }
  }
  fDangerSure() {
    (this as any).$refs.dangerform.validate().then(async () => {
      let flag = this.dangerTypeColumns.every((item: any) => {
        return item.name !== this.addDangerValue;
      });
      if (!flag) {
        return this.$toast.success({
          duration: 1500,
          message: "该类型已经创建，请从列表中选择",
          forbidClick: true,
        });
      }
      let res: any = await api.dangerTypeSave({
        name: this.addDangerValue,
        projectId: this.$store.state.project.projectinfo.projectId,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.$toast.success({
        message: res.msg,
        forbidClick: true,
        onClose: () => {
          this.addDangerValue = "";
          this.dangerDialog = false;
        },
      });
    });
  }
  fDangerSourceSure() {
    (this as any).$refs.dangersourceform.validate().then(async () => {
      let flag = this.dangerSourceIdPickerColumns.every((item: any) => {
        return item.name !== this.dangersourcevalue;
      });
      if (!flag) {
        return this.$toast.success({
          duration: 1500,
          message: "该安全位置已经创建，请从列表中选择",
          forbidClick: true,
        });
      }
      let res: any = await api.dangerSourceSave({
        name: this.dangersourcevalue,
        areaId: this.submitform.areaId,
        projectId: this.$store.state.project.projectinfo.projectId,
        remark: this.dangersourceremark,
      });
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.$toast.success({
        message: res.msg,
        forbidClick: true,
        onClose: () => {
          this.dangersourcevalue = "";
          this.dangersourceremark = "";
          this.dangerSourceDialog = false;
        },
      });
    });
  }
  fShowPersonliableDialog() {
    (this as any).$refs.personliable.show = true;
  }
  setChangePerson(obj: any) {
    this.submitform.changePersonId = obj.changePersonId;
    this.personliableName = obj.personliableName;
  }
  fGoBack() {
    (this as any).$refs.form.resetValidation();
    this.submitform = {
      title: "",
      areaId: "",
      dangerTypeId: "",
      dangerSourceId: "",
      result: "",
      remark: "",
      fileIds: "",
      changePersonId: "",
    };
    this.uploadFiles = [];
    this.areaName = "";
    this.dangerTypeIdname = "";
    this.dangerSourceIdname = "";
    this.resultname = "";
    this.personliableName = "";
    this.$router.go(-1);
  }
}
